<div class="container">
	<div class="row justify-content-center">
		<div class="col-6" style="margin-top: 110px;">
			<div class="bg-white mat-elevation-z3 rounded mb-5">
				<mat-tab-group mat-stretch-tabs dynamicHeight>
					<mat-tab label="Update Info">
						<div class="p-5">
							<form [formGroup]="updateInfoFormGroup" (ngSubmit)="handleUpdateInfo()">
								<mat-form-field class="w-100 pb-2" appearance="outline">
									<mat-label>Say Something About You</mat-label>
									<textarea matInput formControlName="intro" placeholder="Say Something About You" cdkTextareaAutosize
										cdkAutosizeMinRows="2" cdkAutosizeMaxRows="5">
									</textarea>
									<mat-error *ngIf="updateInfoIntro.hasError('maxlength') || updateInfoIntro.hasError('serverError')">
										Cannot exceed 100 characters
									</mat-error>
								</mat-form-field>

								<mat-form-field class="w-100 pb-2" appearance="outline">
									<mat-label>First Name</mat-label>
									<input type="text" matInput formControlName="firstName" placeholder="Ex. John">
									<mat-error *ngIf="updateInfoFirstName.hasError('required') || updateInfoFirstName.hasError('maxlength') || updateInfoFirstName.hasError('serverError')">
										This field is required and cannot exceed 64 characters
									</mat-error>
								</mat-form-field>

								<mat-form-field class="w-100 pb-2" appearance="outline">
									<mat-label>Last Name</mat-label>
									<input type="text" matInput formControlName="lastName" placeholder="Ex. Doe">
									<mat-error *ngIf="updateInfoLastName.hasError('required') || updateInfoLastName.hasError('maxlength') || updateInfoLastName.hasError('serverError')">
										This field is required and cannot exceed 64 characters
									</mat-error>
								</mat-form-field>

								<mat-form-field class="w-100 pb-2" appearance="outline">
									<mat-label>Hometown</mat-label>
									<input type="text" matInput formControlName="hometown" placeholder="Ex. Dhaka">
									<mat-error *ngIf="updateInfoHometown.hasError('maxlength') || updateInfoHometown.hasError('serverError')">
										Cannot exceed 128 characters
									</mat-error>
								</mat-form-field>

								<mat-form-field class="w-100 pb-2" appearance="outline">
									<mat-label>Current City</mat-label>
									<input type="text" matInput formControlName="currentCity" placeholder="Ex. Dhaka">
									<mat-error *ngIf="updateInfoCurrentCity.hasError('maxlength') || updateInfoCurrentCity.hasError('serverError')">
										Cannot exceed 128 characters
									</mat-error>
								</mat-form-field>

								<mat-form-field class="w-100 pb-2" appearance="outline">
									<mat-label>College/University</mat-label>
									<input type="text" matInput formControlName="eduInstitution" placeholder="Ex. University of XYZ">
									<mat-error *ngIf="updateInfoEduInstitution.hasError('maxlength') || updateInfoEduInstitution.hasError('serverError')">
										Cannot exceed 128 characters
									</mat-error>
								</mat-form-field>

								<mat-form-field class="w-100 pb-2" appearance="outline">
									<mat-label>Workplace</mat-label>
									<input type="text" matInput formControlName="workplace" placeholder="Ex. Software Engineer at ABC Inc.">
									<mat-error *ngIf="updateInfoWorkplace.hasError('maxlength') || updateInfoWorkplace.hasError('serverError')">
										Cannot exceed 128 characters
									</mat-error>
								</mat-form-field>

								<mat-form-field appearance="outline" class="d-block pb-2">
									<mat-label>Select your birth date</mat-label>
									<input formControlName="birthDate" matInput [matDatepicker]="birthDatePicker">
									<mat-datepicker-toggle matSuffix [for]="birthDatePicker"></mat-datepicker-toggle>
									<mat-datepicker touchUi #birthDatePicker></mat-datepicker>
									<mat-error *ngIf="updateInfoBirthDate.hasError('serverError')">
										Enter a valid birth
									</mat-error>
								</mat-form-field>

								<mat-form-field  appearance="outline" class="d-block pb-2">
									<mat-label>Select your country</mat-label>
									<mat-select formControlName="countryName">
										<mat-option *ngFor="let country of countryList" [value]="country.name">
										{{ country.name }}
										</mat-option>
									</mat-select>
									<mat-error *ngIf="updateInfoCountryName.hasError('serverError')">
										Enter a valid country name
									</mat-error>
								</mat-form-field>

								<mat-radio-group formControlName="gender" class="d-block mb-2">
									<mat-radio-button value="Male" class="me-3">Male</mat-radio-button>
									<mat-radio-button value="Female">Female</mat-radio-button>
									<mat-error *ngIf="updateInfoGender.hasError('serverError')">
										Enter a valid gender
									</mat-error>
								</mat-radio-group>

								<span class="d-block mb-4 pb-2"></span>

								<mat-card-actions>
									<button mat-raised-button color="primary" type="submit">Save Updates</button>
								</mat-card-actions>
							</form>
						</div>

						<mat-progress-bar mode="indeterminate" *ngIf="submittingForm"></mat-progress-bar>
					</mat-tab>

					<mat-tab label="Update Email">
						<div class="p-5">
							<form [formGroup]="updateEmailFormGroup" (ngSubmit)="handleUpdateEmail()">
								<mat-form-field class="w-100 pb-2" appearance="outline">
									<mat-label>New Email</mat-label>
									<input type="text" matInput formControlName="email" placeholder="Ex. John">
									<mat-error *ngIf="updateEmailNewEmail.hasError('email') || updateEmailNewEmail.hasError('serverError')">
										Enter a valid email address
									</mat-error>
									<mat-error *ngIf="updateEmailNewEmail.hasError('required') || updateEmailNewEmail.hasError('maxlength')">
										This field is required and cannot exceed 64 characters
									</mat-error>
								</mat-form-field>

								<mat-form-field class="w-100 pb-2" appearance="outline">
									<mat-label>Password</mat-label>
									<input type="password" matInput formControlName="password" placeholder="*************">
									<mat-error *ngIf="updatePasswordNewPassword.hasError('required')">
										Password is <b>required</b>
									</mat-error>
									<mat-error *ngIf="updatePasswordNewPassword.hasError('minlength', 'maxlength')">
										Password must be 6 to 32 characters long
									</mat-error>
									<mat-error *ngIf="updatePasswordNewPassword.hasError('serverError')">
										Password has to be alpha numeric and must include uppercase, lowercase, and special characters
									</mat-error>
								</mat-form-field>

								<mat-card-actions>
									<button mat-raised-button color="primary" type="submit">Change Email</button>
								</mat-card-actions>
							</form>
						</div>

						<mat-progress-bar mode="indeterminate" *ngIf="submittingForm"></mat-progress-bar>
					</mat-tab>

					<mat-tab label="Update Password">
						<div class="p-5">
							<form [formGroup]="updatePasswordFormGroup" (ngSubmit)="handleUpdatePassword()">
								<mat-form-field class="w-100 pb-2" appearance="outline">
									<mat-label>New Password</mat-label>
									<input type="password" matInput formControlName="password" placeholder="*************">
									<mat-error *ngIf="updatePasswordNewPassword.hasError('required')">
										Password is <b>required</b>
									</mat-error>
									<mat-error *ngIf="updatePasswordNewPassword.hasError('minlength', 'maxlength')">
										Password must be 6 to 32 characters long
									</mat-error>
									<mat-error *ngIf="updatePasswordNewPassword.hasError('serverError')">
										Password has to be alpha numeric and must include uppercase, lowercase, and special characters
									</mat-error>
								</mat-form-field>
				
								<mat-form-field class="w-100 pb-2" appearance="outline">
									<mat-label>Repeat New Password</mat-label>
									<input type="password" matInput formControlName="passwordRepeat" placeholder="*************" [errorStateMatcher]="repeatPasswordMatcher">
									<mat-error *ngIf="updatePasswordPasswordRepeat.hasError('required')">
										Password Repeat is <b>required</b>
									</mat-error>
									<mat-error *ngIf="updatePasswordFormGroup.hasError('passwordMissMatch') || updatePasswordPasswordRepeat.hasError('serverError')">
										Password did not match
									</mat-error>
								</mat-form-field>

								<mat-form-field class="w-100 pb-3" appearance="outline">
									<mat-label>Old Password</mat-label>
									<input type="password" matInput formControlName="oldPassword" placeholder="*************">
									<mat-error *ngIf="updatePasswordOldPassword.hasError('required')">
										Password is <b>required</b>
									</mat-error>
									<mat-error *ngIf="updatePasswordOldPassword.hasError('minlength', 'maxlength')">
										Password must be 6 to 32 characters long
									</mat-error>
									<mat-error *ngIf="updatePasswordOldPassword.hasError('serverError')">
										Password has to be alpha numeric and must include uppercase, lowercase, and special characters
									</mat-error>
								</mat-form-field>

								<mat-card-actions>
									<button mat-raised-button color="primary" type="submit">Change Password</button>
								</mat-card-actions>
							</form>
						</div>

						<mat-progress-bar mode="indeterminate" *ngIf="submittingForm"></mat-progress-bar>
					</mat-tab>
				</mat-tab-group>
			</div>
		</div>
	</div>
</div>