<mat-dialog-content>
	<div class="position-relative">
		<h2 class="mat-h2 m-0">
			Share this post
		</h2>
		<hr class="divider">
	
		<form [formGroup]="shareFormGroup" (ngSubmit)="createNewPostShare()"
			class="position-relative">
			<mat-form-field appearance="outline" class="w-100" style="padding-right: 90px;">
				<mat-label>What do you want to say</mat-label>
				<textarea matInput formControlName="content" placeholder="What do you want to say"></textarea>
				<mat-error *ngIf="content.hasError('maxlength')">
					Cannot exceed 4096 characters
				</mat-error>
			</mat-form-field>
			<button mat-raised-button color="primary" type="submit" class="position-absolute"
				style="top: 4px; right: 0;">Share</button>
		</form>

		<div *ngIf="creatingShare" class="position-absolute" style="transform: scale(0.3); top: -35px; right: -35px;">
			<mat-spinner></mat-spinner>
		</div>
	</div>
</mat-dialog-content>