<div class="text-center fully-centered" *ngIf="loadingTimelinePostsInitially || loadingTimelineTagsInitially">
	<mat-spinner style="zoom: 0.5;" class="text-center ms-auto me-auto"></mat-spinner>
</div>

<div style="margin-top: 100px" *ngIf="!loadingTimelinePostsInitially && !loadingTimelineTagsInitially">
	<div class="container">
		<div class="row justify-content-center mt-5">
			<div class="col-4">
				<div class="profile-sidebar position-sticky bg-white rounded mat-elevation-z3 p-3" style="top: 64px;">
					<div *ngIf="timelineTagList.length > 0">
						<h2 class="mat-h2">Most Used Tags</h2>
						<a class="d-block p-3 text-decoration-none fw-500 border border-1 border-start-0 border-end-0"
						 style=" font-size: 14px;"
						 *ngFor="let tag of timelineTagList; let last = last;" href="/posts/tags/{{ tag.name }}" [ngClass]="last ? '' : 'border-bottom-0'">
							#{{ tag.name }} <span class="badge rounded-pill text-dark float-end" style="background-color: rgba(0,0,0,0.15);">{{ tag.tagUseCounter }}</span>
						</a>
					</div>
					<div *ngIf="timelineTagList.length === 0">
						<h2 class="mat-h2 m-0 text-center">No Tags Found</h2>
					</div>
				</div>
			</div>

			<div class="col-6">
				<h3 class="mat-h3 fw-500" *ngIf="isTaggedPostPage">Posts with tag <i>#{{ targetTagName }}</i></h3>

				<div class="timeline-posts">
					<p class="fw-500 text-center" *ngIf="noPost">No Post Found</p>
					<app-post *ngFor="let postResponse of timelinePostResponseList" id="{{'profilePost' + postResponse.post.id}}" [postResponse]="postResponse" [isDetailedPost]="false"></app-post>
				</div>

				<div class="text-center mb-4">
					<mat-spinner style="transform: scale(0.3);" class="text-center ms-auto me-auto" *ngIf="fetchingResult"></mat-spinner>
					<button mat-raised-button color="primary" class="mat-btn-lg rounded-pill" *ngIf="hasMoreResult && !fetchingResult && !isTaggedPostPage" (click)="loadTimelinePosts(resultPage)">Load More</button>
					<button mat-raised-button color="primary" class="mat-btn-lg rounded-pill" *ngIf="hasMoreResult && !fetchingResult && isTaggedPostPage" (click)="loadTaggedPosts(targetTagName, resultPage)">Load More</button>
				</div>
			</div>
		</div>
	</div>
</div>