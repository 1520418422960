<mat-dialog-content>
	<div class="position-relative">
		<h2 class="mat-h2 m-0">
			{{ dataPost.commentCount > 0 ? 'Comments(' + dataPost.commentCount + ')' : 'No Comment' }}
		</h2>
	
		<hr class="divider">
	
		<form [formGroup]="commentFormGroup" #commentForm="ngForm" (ngSubmit)="createNewComment()"
			class="position-relative">
			<mat-form-field appearance="outline" class="w-100" style="padding-right: 90px;">
				<mat-label>Post new comment</mat-label>
				<textarea matInput formControlName="content" placeholder="Post new comment"></textarea>
				<mat-error *ngIf="content.hasError('required') && commentForm.submitted">
					Comment cannot be empty
				</mat-error>
				<mat-error *ngIf="content.hasError('maxlength')">
					Comment length cannot exceed 1024 characters
				</mat-error>
			</mat-form-field>
			<button mat-raised-button color="primary" type="submit" class="position-absolute"
				style="top: 4px; right: 0;">Submit</button>
		</form>
	
		<ul class="list-group list-group-flush">
			<li class="list-group-item ps-0 pt-3 pe-0 pb-3" *ngFor="let commentResponse of commentResponseList">
				<div class="rounded-circle d-inline-block align-middle centered-bg-image"
					style="width: 35px; height: 35px; background-image: url('{{commentResponse.comment.author.profilePhoto ? commentResponse.comment.author.profilePhoto : defaultProfilePhotoUrl}}');">
				</div>
				<div class="ps-5" style="margin-top: -38px;">
					<a class="d-inline-block text-decoration-none text-dark align-middle fw-500 cursor-pointer"
						 href="/users/{{commentResponse.comment.author.id}}">{{commentResponse.comment.author.firstName + ' ' +
						 commentResponse.comment.author.lastName}}</a>
					<br>
					<span class="text-secondary d-inline-block">
						<mat-icon class="align-middle w-auto h-auto" style="font-size: 16px;">watch_later</mat-icon>
						<span class="align-middle ps-1 small fw-500">{{ commentResponse.comment.dateCreated | date:'medium' }}</span>
					</span>
	
					<p class="pt-2 pb-3">{{ commentResponse.comment.content }}</p>
	
					<mat-chip-list aria-label="Fish selection">
						<mat-chip class="me-3 cursor-pointer" style="padding: 4px 10px !important; min-height: 24px;" (click)="openCommentLikeDialog(commentResponse.comment)">
							Likes
							<span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-secondary">
								{{ commentResponse.comment.likeCount }}
							</span>
						</mat-chip>
					</mat-chip-list>
	
					<div style="margin-top: -40px; text-align: right;">
						<button mat-icon-button matTooltip="{{commentResponse.likedByAuthUser ? 'Unlike' : 'Like'}}" (click)="likeOrUnlikeComment(commentResponse)">
							<mat-icon [ngClass]="commentResponse.likedByAuthUser ? 'text-danger' : 'text-secondary'">favorite</mat-icon>
						</button>
						<button mat-icon-button matTooltip="Delete" *ngIf="commentResponse.comment.author.id === authUserId"  (click)="openCommentDeleteConfirmDialog(commentResponse)">
							<mat-icon class="text-secondary">delete_sweep</mat-icon>
						</button>
					</div>
				</div>
			</li>
		</ul>

		<div class="mt-3 mb-3 text-center" *ngIf="hasMoreResult">
			<button mat-raised-button color="primary" class="rounded-pill align-middle" *ngIf="hasMoreResult && !(fetchingResult && resultPage > 1)" (click)="loadComments(resultPage)">Load More</button>
			<div *ngIf="fetchingResult && resultPage > 1" class="d-inline-block" style="transform: scale(0.3); margin-top: -33px;">
				<mat-spinner style="margin: 0 auto;"></mat-spinner>
			</div>
		</div>
	
		<div *ngIf="(fetchingResult && resultPage === 1) || creatingComment" class="position-absolute" style="transform: scale(0.3); top: -35px; right: -35px;">
			<mat-spinner></mat-spinner>
		</div>
	</div>
</mat-dialog-content>