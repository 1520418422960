<mat-dialog-content >
	<div class="position-relative">
		<h2 class="mat-h2 m-0">
			Retrieve Password
		</h2>
	
		<hr class="divider">
	
		<form [formGroup]="forgotPasswordFormGroup" class="position-relative" (ngSubmit)="sendForgotPasswordEmail()">
			<mat-form-field appearance="outline" class="w-100" style="padding-right: 90px;">
				<mat-label>Email</mat-label>
				<input matInput formControlName="email" placeholder="Email">
				<mat-error *ngIf="email.hasError('required')">
					Email is <b>required</b>
				</mat-error>
				<mat-error *ngIf="email.hasError('email') || email.hasError('maxlength')">
					Must be a valid email address
				</mat-error>
			</mat-form-field>

			<button mat-raised-button color="primary" type="submit" class="position-absolute"
			style="top: 4px; right: 0;">Retrieve</button>
		</form>
	
		<div *ngIf="fetchingResult" class="position-absolute" style="transform: scale(0.3); top: -35px; right: -35px;">
			<mat-spinner></mat-spinner>
		</div>
	</div>

</mat-dialog-content>