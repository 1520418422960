<div class="mt-100 me-auto ms-auto" style="width: 500px;">
	<mat-card class="example-card">
		<mat-card-title>Verifying</mat-card-title>

		<mat-card-content>
			Please, wait while we verity your email address.
		</mat-card-content>
		
		<mat-card-footer>
			<mat-progress-bar mode="indeterminate"></mat-progress-bar>
		</mat-card-footer>
	</mat-card>
</div>