<mat-toolbar color="primary" class="position-fixed top-0 mat-elevation-z3 p-4" style="z-index: 999;">
	<a href="https://www.naaraa.com" class="text-white text-decoration-none">Naaraa</a>

	<span class="example-spacer"></span>

	<a href="/profile" class="rounded-pill ps-2 pt-1 pe-3 pb-1 text-white text-decoration-none" *ngIf="isUserLoggedIn">
		<span class="rounded-circle d-inline-block align-middle centered-bg-image"
			style="width: 30px; height: 30px; background-color: white; background-image: url('{{authUser.profilePhoto ? authUser.profilePhoto : defaultProfilePhotoUrl}}');"></span>
		<span class="mat-body-strong d-inline-block align-middle ps-2">{{ authUser?.firstName }}</span>
	</a>

	<a mat-raised-button class="me-3" *ngIf="!isUserLoggedIn" href="/login">Login</a>
	<a mat-raised-button *ngIf="!isUserLoggedIn" href="/signup">Signup</a>

	<div *ngIf="isUserLoggedIn">
		<button mat-icon-button (click)="openSearchDialog()">
			<mat-icon>search</mat-icon>
		</button>

		<button mat-icon-button [matMenuTriggerFor]="notificationMenu" (click)="handleUnseenNotifications()">
			<mat-icon>notifications</mat-icon>
			<span class="position-absolute translate-middle p-2 bg-danger rounded-circle" style="top: 10px; right: -5px;" *ngIf="hasUnseenNotification"></span>
		</button>
		<mat-menu #notificationMenu="matMenu" xPosition="before">
			<h2 class="mat-h2 m-0 text-center" style="padding: 12px 36px" *ngIf="notifications.length <= 0" (click)="$event.stopPropagation();">No Notification</h2>
			<h2 class="mat-h2 m-0 text-center" style="padding: 12px 20px 20px 20px;" *ngIf="notifications.length > 0" (click)="$event.stopPropagation();">Notifications</h2>

			<a mat-menu-item *ngFor="let notification of notifications; let last = last;"
			 href="/posts/{{ notification.owningPost.id }}"
			 class="border border-1 border-start-0 border-end-0"
			 [ngClass]="last ? '' : 'border-bottom-0'"
			 style="height: auto; line-height: 1.6; white-space: normal; font-size: 14px; padding: 16px 20px;">
			 	<mat-icon color="primary" style="margin-right: 5px;" *ngIf="notification.type === 'POST_LIKE' || notification.type === 'COMMENT_LIKE'">favorite</mat-icon>
			 	<mat-icon color="primary" style="margin-right: 5px;" *ngIf="notification.type === 'POST_COMMENT'">comment</mat-icon>
			 	<mat-icon color="primary" style="margin-right: 5px;" *ngIf="notification.type === 'POST_SHARE'">share</mat-icon>

			 	<span class="fw-500" *ngIf="notification.sender">{{ notification.sender.firstName + ' ' + notification.sender.lastName }}</span>
			 	<span class="fw-500 text-danger" *ngIf="!notification.sender">An App User</span>

				<span *ngIf="notification.type === 'POST_LIKE'">
					<span *ngIf="notification.owningPost.likeCount > 1">
						and
						<span class="fw-500">
							{{ notification.owningPost.likeCount - 1 }}
							other
						</span>
					</span>
					 liked your
					<span *ngIf="notification.owningPost.content">
						post "{{ notification.owningPost.content | slice:0:44 }}..."
					</span>
					<span *ngIf="!notification.owningPost.content">
						photo
					</span>
				</span>
				
				<span *ngIf="notification.type === 'POST_COMMENT'">
					<span *ngIf="notification.owningPost.commentCount > 1">
						and
						<span class="fw-500">
							{{ notification.owningPost.commentCount - 1 }}
							other
						</span>
					</span>
					commented on your
					<span *ngIf="notification.owningPost.content">
						post "{{ notification.owningPost.content | slice:0:24 }}..."
					</span>
					<span *ngIf="!notification.owningPost.content">
						photo
					</span>
				</span>

				<span *ngIf="notification.type === 'POST_SHARE'">
					<span *ngIf="notification.owningPost.shareCount > 1">
						and
						<span class="fw-500">
							{{ notification.owningPost.shareCount - 1 }}
							other
						</span>
					</span>
					shared your
					<span *ngIf="notification.owningPost.content">
						post "{{ notification.owningPost.content | slice:0:24 }}..."
					</span>
					<span *ngIf="!notification.owningPost.content">
						photo
					</span>
				</span>

				<span *ngIf="notification.type === 'COMMENT_LIKE'">
					<span *ngIf="notification.owningComment.likeCount > 1">
						and
						<span class="fw-500">
						{{ notification.owningComment.likeCount - 1	 }}
							other
						</span>
					</span>
					liked your comment "{{ notification.owningComment.content | slice:0:24 }}..."
				</span>
			</a>

			<div (click)="$event.stopPropagation()" class="position-relative ps-3 pe-3">
				<div class="text-center" *ngIf="hasMoreNotifications" style="margin: 20px 12px 12px 12px;">
					<button mat-raised-button color="primary" class="rounded-pill align-middle" *ngIf="hasMoreNotifications && !(fetchingResult && resultPage > 1)" (click)="loadNotifications(resultPage)">Load More</button>
					<div *ngIf="fetchingResult && resultPage > 1" class="d-inline-block" style="margin-top: 3px;">
						<mat-spinner style="zoom: 0.3; margin: 0 auto;"></mat-spinner>
					</div>
				</div>
			</div>
		</mat-menu>
		
		<button mat-icon-button [matMenuTriggerFor]="mainMenu">
			<mat-icon>more_vert</mat-icon>
		</button>
		<mat-menu #mainMenu="matMenu" xPosition="before">
			<a mat-menu-item href="/profile">Profile</a>
			<a mat-menu-item href="/settings">Settings</a>
			<a mat-menu-item href="/logout">Logout</a>
		</mat-menu>
	</div>
</mat-toolbar>

<button mat-fab color="primary" matTooltip="Create New Post" matTooltipPosition="left" class="position-fixed"
	style="bottom: 30px; right: 30px;" (click)="openPostDialog()">
	<mat-icon>add</mat-icon>
</button>
