<div class="mt-100 me-auto ms-auto pb-5" style="width: 500px;">
	<mat-card class="mat-elevation-z3">
		<mat-card-title class="text-center ps-3 pt-5 pe-3">Get A New Password</mat-card-title>

		<mat-card-content class="p-4">
			<form [formGroup]="resetPasswordFormGroup" (ngSubmit)="resetPassword()">
				<mat-form-field class="w-100 pb-2" appearance="outline">
					<mat-label>Password</mat-label>
					<input type="password" matInput formControlName="password" placeholder="*************">
					<mat-error *ngIf="password.hasError('required')">
						Password is <b>required</b>
					</mat-error>
					<mat-error *ngIf="password.hasError('minlength', 'maxlength')">
						Password must be 6 to 32 characters long
					</mat-error>
					<mat-error *ngIf="password.hasError('serverError')">
						Password has to be alpha numeric and must include uppercase, lowercase, and special characters
					</mat-error>
				</mat-form-field>

				<mat-form-field class="w-100 pb-2" appearance="outline">
					<mat-label>Repeat Password</mat-label>
					<input type="password" matInput formControlName="passwordRepeat" placeholder="*************" [errorStateMatcher]="repeatPasswordMatcher">
					<mat-error *ngIf="passwordRepeat.hasError('required')">
						Password Repeat is <b>required</b>
					</mat-error>
					<mat-error *ngIf="resetPasswordFormGroup.hasError('passwordMissMatch') || passwordRepeat.hasError('serverError')">
						Password did not match
					</mat-error>
				</mat-form-field>

				<mat-card-actions align="end" style="padding-right: 8px;">
					<button mat-raised-button color="primary" type="submit">Submit</button>
				</mat-card-actions>
			</form>
		</mat-card-content>

		<mat-card-footer *ngIf="fetchingResult">
			<mat-progress-bar mode="indeterminate"></mat-progress-bar>
		</mat-card-footer>
	</mat-card>
</div>